import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import MarkdownBlogOverview from "../containers/blog/markdown-blog-overview";
import { useTranslation } from 'react-i18next';

const YouTube = () => {
  const { t } = useTranslation("common");

  return (
    <Fragment>
      <MetaTags>
        <title>Blog & YouTube</title>
        <meta
          name="description"
          content="My blog including my latest YouTube videos"
        />
        <meta property="og:url" content="https://christianoette.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="christianoette.com" />
        <meta property="og:image" content="https://christianoette.com/meta/avatar.png" />
        <meta property="og:description" content={t("blogdescription")} />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Blog & YouTube" />

        {/* blog post with sidebar */}
        <MarkdownBlogOverview />
      </LayoutOne>
    </Fragment>
  );
};

export default YouTube;

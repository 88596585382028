import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import SimpleTextPage from "../containers/simple-text-page"
import Breadcrumb from "../components/UI/breadcrumb";
import SectionTitleTwo from "../components/UI/section-title/section-title-two";
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation("common");
  const contact = t("contact");

  return (
    <Fragment>
      <MetaTags>
        <title>{contact}</title>
        <meta
          name="description"
          content={contact}
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title={contact} />        
        <SimpleTextPage
          children={
            <>
              <SectionTitleTwo
                title={contact}
                text={t("writemeanemail")}
              />              

              <p>{t("reachme")} <a href='mailto:kontakt@christian-oette.de'>kontakt(at)christian-oette.de</a></p>              
              <p>{t("visithomepage")} <br/>
                <a href='https://christian-oette.de'>https://christian-oette.de</a>.</p>
            </>
          }
        />
      </LayoutOne>
    </Fragment>
  );
};

export default Contact;

import React from "react";
import styles from "./HelpFeatured.module.scss";
import { I18nButton } from "../../../components/translation"
import { useTranslation } from 'react-i18next';


const HelpFeatured = () => {
  const englishCourses =[
    {
      name: "Master Spring Batch",
      linkToCourse: "linkToUdemy",
      descriptionId: "courseMasterSpringBatch",
      link: "https://www.udemy.com/course/mastering-spring-batch/?referralCode=20AE1539B9952676BFCD"
    },  
  ];

  const germanCourses = [{
    name: "Mockito",
    linkToCourse: "linkToUdemy",
    descriptionId: "courseMockito",
    link: "https://www.udemy.com/course/mockito-testing/?referralCode=B6B7ADE759A454EA6342"
  },
  {
    name: "Spring und Hibernate für Anfänger",
    linkToCourse: "linkToUdemy",
    descriptionId: "courseSpring",
    link: "https://www.udemy.com/course/spring-beginner/?referralCode=0D7BD3A40BA6C44A5685"
  },
  {
    name: "Professionelle Restful APIs mit Java und Spring Boot",
    linkToCourse: "linkToUdemy",
    descriptionId: "courseSpringRestful",
    link: "https://www.udemy.com/course/restful-apis-spring-boot/?referralCode=8456AE43ACB6BAF500DA"
  },
  {
    name: "Spring Batch",
    linkToCourse: "linkToUdemy",
    descriptionId: "courseMasterSpringBatch",
    link: "https://www.udemy.com/course/spring-batch-auf-basis-von-spring-boot/?referralCode=E08D57DFDF2692275423"
  },
  {
    name: "AWS IAM Grundlagen",
    linkToCourse: "linkToUdemy",
    descriptionId: "courseIAM",
    link: "https://www.udemy.com/course/aws-iam-praktische-anwendung/?referralCode=27D6F9806071E2D32945"
  },
  {
    name: "AWS API Gateway REST",
    linkToCourse: "linkToHeiseAcademy",
    descriptionId: "courseApiGateway",
    link: "https://shop.heise-academy.de/aws-api--gateway-rest"
  }
  ];
  const { t } = useTranslation("courses");

  return (
    <div className="courses">
      <div className="container">

        <div className={styles.courses}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2 className={styles.title}>{t("troubleshooting")}</h2>
              <p>
                {t("troubleshootingText")}
              </p>
              <I18nButton ns="courses" id="support" />
            </div>
          </div>
          <div className={styles.spacer}/>
          <div className="row">
            <div className="col-12">
              <h2 className={styles.title}><span role="img" aria-labelledby="english flag">🇺🇸</span> {t("titleEnglishCourses")}</h2>
            </div>                        
            {
              showCourses(englishCourses, t)
            }
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className={styles.title}><span role="img" aria-labelledby="german flag">🇩🇪</span> {t("titleGermanCourses")} </h2>
            </div>                        
            {
              showCourses(germanCourses, t)
            }
          </div>
        </div>
      </div>
    </div >
  );
};

export default HelpFeatured;

function showCourses(courses, t) {
  return courses.map((course) => <div key={course.name} className="col-12 col-lg-6">
    <div className={styles.item}>
      <div>        
        <div>
            <h2>              
              {course.name}
            </h2>
            <br />          
        </div>
        <div>          
          <span>{t(course.descriptionId)}</span>
        </div>
        <div className={styles.goToCourse}>          
          <I18nButton ns="courses" id={t(course.linkToCourse)} url={course.link} />
        </div>
      </div>
    </div>
  </div>);
}


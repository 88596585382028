import React from "react";
import {
    FaRegEnvelope, FaGithub, FaYoutube
} from "react-icons/fa";

const MobileWidgets = ({ styles }) => {
  return (
    <div className={styles.offcanvasWidgetArea}>
      <div className={styles.offCanvasContactWidget}>
        <div className={styles.headerContactInfo}>
          <ul className={styles.headerContactInfoList}>
            <li>
              <FaRegEnvelope />{" "}
              <a href="mailto:kontakt@christian-oette.de">kontakt@christian-oette.de</a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className={styles.offCanvasWidgetSocial}>
        <a
          href="https://github.com/Christian-Oette"
          target="_blank"
          rel="noopener noreferrer"
          title="GitHub"
        >
          <FaGithub />
        </a>
        <a
          href="https://www.youtube.com/channel/UCcXPq0rvN_0-elBiY23C7pA"
          target="_blank"
          rel="noopener noreferrer"
          title="YouTube"
        >
          <FaYoutube />
        </a>
      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import SimpleTextPage from "../containers/simple-text-page"
import Breadcrumb from "../components/UI/breadcrumb";
import SectionTitleTwo from "../components/UI/section-title/section-title-two";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation("common");
  const about = t("aboutme");

  return (
    <Fragment>
    <MetaTags>
      <title>{about}</title>
      <meta
        name="description"
        content={about}
      />      
      <meta property="og:type" content="website" />
      <meta property="og:title" content={t("aboutme")} />      
      <meta property="og:description" content={about} />
    </MetaTags>
    <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title={about} />
        <SimpleTextPage
          children={
            <>
              <SectionTitleTwo
                title="Christian Oette"
                text={t("abouttheauthor")}
              />              
            <p>{t("aboutcontent")}</p>
          </>
          }></SimpleTextPage>                
      </LayoutOne>
    </Fragment>
  );
};

export default About;

import React from "react";
import styles from "./Navigation.module.scss";
import I18nLink from "../translation";

const Logo = () => {

    return (
        <nav>
            <ul className={styles.mainMenu}>
                <li >
                    <I18nLink path="blog" content="christianoette.com" />
                </li>
            </ul>
        </nav>);
};

export default Logo;

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";

const Button = ({ url, text }) => {
    return (
      <Link
        className={`${styles.voopoBtn}`}
        to={url}
      >
        {text}
      </Link>
    );  
};

Button.propTypes = {
  btnStyle: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string
};

export default Button;

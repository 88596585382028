import React from "react";
import styles from "./SimpleTextPage.module.scss";

const SimpleTextPage = ({children}) => {
  return (
    <div className={`${styles.voopoAboutArea} position-relative bg--cart-11`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="aboutInner">              
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleTextPage;

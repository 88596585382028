import React from "react";
import I18nLink from "../../translation";
import { useTranslation } from 'react-i18next';

const MobileNavMenu = ({ styles }) => {

  const { i18n } = useTranslation("menu");

  return (
    <nav className={styles.offcanvasNavigation} id="offcanvas-navigation">
      <ul>
        <li className={styles.menuItemHasChildren}>
          <I18nLink ns="menu" id="blog"/>
        </li>
        <li>
          <I18nLink ns="menu"  path="courses" id="onlinecourses"/>
        </li>
        <li className={styles.menuItemHasChildren}>
          <I18nLink ns="menu"  path="about" id="aboutme"/>
        </li>
        <li>
          <I18nLink ns="menu"  id="contact"/>
        </li>
        <li>
            <br></br>
        </li>
        <li>
        <a href="#de" onClick={navigateTo(i18n, "de")}> German (DE) </a>
        <a href="#de" onClick={navigateTo(i18n, "en")}> English (EN)</a>          
        </li>
      </ul>
    </nav>
  );
};

const navigateTo = (i18n, lang) => {
  return function () {
    i18n.changeLanguage(lang);    
  }
}

export default MobileNavMenu;

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import SimpleTextPage from "../containers/simple-text-page"
import SectionTitleTwo from "../components/UI/section-title/section-title-two";
import { useTranslation } from 'react-i18next';

const DataPrivacy = () => {
  const { t } = useTranslation("common");
  const impressum = t("dataprivacy");

  return (
    <Fragment>
      <MetaTags>
        <title></title>
        <meta
          name="description"
          content={impressum}
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title={impressum} />
        <SimpleTextPage
          children={
            <>
              <SectionTitleTwo
                title={impressum}
                text={t("dataprivacytitle")}
              />

              <div dangerouslySetInnerHTML={
                { __html: t('dataprivacycontent', { interpolation: { escapeValue: false } }) }
              } />
            </>
          }
        />
      </LayoutOne>
    </Fragment>
  );
};

export default DataPrivacy;

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import SimpleTextPage from "../containers/simple-text-page"
import SectionTitleTwo from "../components/UI/section-title/section-title-two";
import { useTranslation } from 'react-i18next';

const Impressum = () => {
  const { t } = useTranslation("common");
  const impressum = t("impressum");

  return (
    <Fragment>
      <MetaTags>
        <title></title>
        <meta
          name="description"
          content={impressum}
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title={impressum} />
        <SimpleTextPage
          children={
            <>
              <SectionTitleTwo
                title={impressum}
                text={t("aboutauthor")}
              />              
              <p>Christian Oette</p>
              <p>Maienweg 321</p>
              <p>22335 Hamburg / Germany</p>
              <p>kontakt@christian-oette.de</p>
            </>
          }
        />
      </LayoutOne>
    </Fragment>
  );
};

export default Impressum;

import React from "react";
import styles from "./Navigation.module.scss";
import { useTranslation } from 'react-i18next';
import I18nLink from '../translation'

const Navigation = () => {

  const { i18n } = useTranslation("menu");  

  return (
    <nav>
      <ul className={styles.mainMenu}>
        <li>
          <I18nLink ns="menu" id="blog" />
        </li>
        <li>
          <I18nLink ns="menu" id="courses" />
        </li>
        <li>
          <I18nLink ns="menu" id="about"/>
        </li>
        <li>
          <I18nLink ns="menu" id="contact" />
        </li>
        <li>
          <a href="#de" onClick={changeLanguage(i18n, "de")}> DE </a>
          |
          <a href="#en" onClick={changeLanguage(i18n, "en")}> EN </a>
        </li>
      </ul>
    </nav>
  );
};

const changeLanguage = (i18n, lang) => {
  return function (e) {  
    i18n.changeLanguage(lang);    
  }
}

export default Navigation
import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import HelpFeatured from "../containers/help/help-featured";
import { useTranslation } from 'react-i18next';

const OnlineCourses = () => {

  const {t} = useTranslation("courses");

  return (
    <Fragment>
      <MetaTags>
        <title>{t("meta")}</title>
        <meta
          name="description"
          content={t("meta")}
        />
      </MetaTags>
      <LayoutOne lang="en">        
        <Breadcrumb title={t("onlinecourses")} />        
        <HelpFeatured />                
      </LayoutOne>
    </Fragment>
  );
};

export default OnlineCourses;

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import SectionTitleTwo from "../components/UI/section-title/section-title-two";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";


const YouTube = () => {

    const german = isGerman(getLanguage());

    return (
        <Fragment>
            <MetaTags>
                <title>Language Pick</title>
                <meta
                    name="description"
                    content="Fallback language selection and redirect page"
                />
            </MetaTags>
            <LayoutOne>
                {/* breadcrumb */}
                <Breadcrumb title="Select your language" />

                <div className="blog__details__area pt--120 pb--110 bg--white">
                    <div className="container">
                        <div className="row">
                            <div className="languageSelect">

                                <SectionTitleTwo
                                    title="Language Selection"
                                    text="English or German ?"
                                />
                                <p>If you are not automatically forwarded, pick your preferred Language!</p>
                                <Link to={process.env.PUBLIC_URL + "/de/blog"}>
                                    German (DE)
                            </Link>
                                <br />
                                <Link to={process.env.PUBLIC_URL + "/en/blog"}>
                                    English (EN)
                            </Link>
                            {
                                german &&
                                <Redirect to={`${process.env.PUBLIC_URL + "/de/blog"}`} />
                            }
                            {
                                !german &&
                                <Redirect to={`${process.env.PUBLIC_URL + "/en/blog"}`} />
                            }                                                
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* blog post with sidebar */}
            </LayoutOne>
        </Fragment>
    );
};

const getLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
const isGerman = (lang) => typeof lang === 'string' && lang.toLowerCase().includes("de");

export default YouTube;

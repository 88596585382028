import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FaSpinner } from "react-icons/fa"

import MarkdownIt from 'markdown-it';
import MarkdowntAttrs from 'markdown-it-attrs';
import Markdown from 'react-markdown';
import highlight from 'markdown-it-highlight';
import markdownVideo from 'markdown-it-video';
import markdownImageSize from 'markdown-it-imsize';
import markDownAnchor from 'markdown-it-anchor';
import e from 'markdown-it-toc-done-right';
import { getLanguageId } from '../../../components/translation'
import { useTranslation } from 'react-i18next';

import 'markdown-it-highlight/dist/index.css'
import styles from "./MarkDownBlogPost.module.scss"

const markDownRenderer = new MarkdownIt();
markdownVideo(markDownRenderer, {
  youtube: { width: 640, height: 390 },
  vimeo: { width: 500, height: 281 },
  vine: { width: 600, height: 600, embed: 'simple' },
  prezi: { width: 550, height: 400 }
});
markdownImageSize(markDownRenderer, {
  html: true,
  linkify: true,
  typography: true
});
markDownAnchor(markDownRenderer);
markDownRenderer.use(e, {
  itemClass: 'tableOfContentsItem'
});
markDownRenderer.use(highlight);
markDownRenderer.use(MarkdowntAttrs, {
  leftDelimiter: '{',
  rightDelimiter: '}',
  allowedAttributes: [] 
});


const MarkDownView = ({ pathPrefix }) => {

  const { i18n } = useTranslation(); 
  const lang = getLanguageId(i18n);
  const {t} = useTranslation("common");
  const errorContent = t("contentnotfound");
  const [state, setState] = useState({ content: t("loading"), spinner: true });
  const { postId } = useParams();
  const url = determineMarkdownFileUrl(lang, pathPrefix, postId);

  useEffect(() => {
    async function fetchData() {
      if (postId) {
       
        await fetch(url)
          .then(response => response.text())
          .then(data => {
            const renderedContent = markDownRenderer.render(data);
            if (renderedContent.match(/DOCTYPE html/g)) {
              setState({ content: errorContent, spinner: false });
            } else {
              setState({ content: renderedContent, spinner: false })
            }
          })
          .catch(err => {
            console.error(err);
            setState({ content: errorContent, spinner: false });
          });
      }
    }
    fetchData();

  }, [postId, pathPrefix, lang, url, errorContent]);


  function determineMarkdownFileUrl(lang, pathPrefix, postId) {
    let url = `${pathPrefix}/${postId}/content.md`;
    if (lang === 'de') {    
      url = `${pathPrefix}/${postId}/content-de.md`;
    }
    return url;
  }

  return (
    <div className="blog__details__area pt--120 pb--110 bg--white">
      <div className="container">
        <div className={styles.mkd} >
          {state.spinner && (
            <div><FaSpinner className="fa-spin" /></div>
          )}
          <Markdown escapeHtml={false} >
            {state.content}
          </Markdown>
        </div>
      </div>
    </div>
  );
};

export default MarkDownView;

import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { FaArrowLeft } from 'react-icons/fa'
import { useTranslation } from 'react-i18next';
import styles from "./CourseSidebar.module.scss";
import I18nLink from "../../components/translation";

const CourseSidebar = ({ items }) => {

  const { t } = useTranslation("courses");  
  const { troubleshoot, additional } = items;
  const troubleShootRows = [];
  const additionalRows = [];

  for (const index in additional) {
    additionalRows.push(createRow(index, additional[index]));
  }

  for (const index in troubleshoot) {
    troubleShootRows.push(createRow(index, troubleshoot[index]));
  }


  return (
    <div className="voopoSidebar">

      {/* Start Recent Post */}
      <div className={`${styles.vpWidget} ${styles.recentPostWrap} mb--60`}>
        <Tab.Container defaultActiveKey="troubleshoot">
          <Nav
            variant="pills"
            className={`${styles.voopoNav} nav justify-content-center`}
          >
            <Nav.Item className={styles.navItem}>
              <Nav.Link eventKey="troubleshoot">{t("troubleshoot")}</Nav.Link>
            </Nav.Item>
            <Nav.Item className={styles.navItem}>
              <Nav.Link eventKey="content">{t("additionalcontent")}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="troubleshoot" className={styles.singleTabContent}>
              {troubleShootRows}
            </Tab.Pane>
            <Tab.Pane eventKey="content" className={styles.singleTabContent}>
              {additionalRows}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      <FaArrowLeft/> &nbsp;

      <I18nLink ns="courses"id ="backtocourses" path="courses"></I18nLink>
    </div>
  );
};

function createRow(index, data) {
  return <div className={styles.recentPost} key={index}>
    <div className={styles.content}>
      <h4>
        <I18nLink content={data.title} path={"coursearticle/" + data.helpId}></I18nLink>
      </h4>
    </div>
  </div>
}

export default CourseSidebar;

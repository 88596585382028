import React from "react";
import { useState, useEffect } from 'react';
import styles from "../BlogPostWithSidebar.module.scss";
import blogMetaInformation from '../../../assets/blog/blog-meta.json';
import I18nLink from "../../../components/translation";
import { getLanguageId } from '../../../components/translation'
import i18n from 'i18next';
import {isMobile} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const NR_OF_ENTRIES_LOADED_ON_CLICK = 5;
const NR_OF_ENTRIES_LOADED_INITIALLY = 20;

const MarkdownBlogOverview = ({ sidebarPosition }) => {
  const {t} = useTranslation("common");
  let initialOffset = 0;
  const latestStoredOffset = parseInt(sessionStorage.getItem("blogOverviewOffset"));
  if (!Number.isNaN(latestStoredOffset)) {
    initialOffset = latestStoredOffset;
  }

  const [offset,setOffset]=useState(initialOffset);   
  console.log("offset is:"+offset);
  
  useEffect(() => {        
    sessionStorage.setItem("blogOverviewOffset", offset);    
  }, [offset]);
  
  const allBlogPosts = listPosts(isMobile, blogMetaInformation, offset);
  const maxEntriesNotYetLoaded = offset + NR_OF_ENTRIES_LOADED_INITIALLY < allBlogPosts.count;
  const loadMoreText = t("loadmoreentries");

  console.log(blogMetaInformation.entries());
  return (

    <div className="voopo__blog__area pb--120" >
      <div className="container" >

        <div className="row">
          <div
            className={`  ${sidebarPosition && sidebarPosition === "left"
              ? "order-1 order-lg-2"
              : ""
              }`}
          >

            <div className={styles.blogListWrapper}>
              {allBlogPosts.posts}
            </div>
            {
              maxEntriesNotYetLoaded && 
              <a href="#/" className={styles.readmoreBtn} 
                onClick={
                  (e)=> {
                    setOffset(offset+NR_OF_ENTRIES_LOADED_ON_CLICK)
                    e.preventDefault();
                  }
                }>{loadMoreText}</a>
            }
            
          </div>
          <div
            className={`col-12 col-lg-4 sm__mt--40 md__mt--40 ${sidebarPosition && sidebarPosition === "left"
              ? "order-2 order-lg-1"
              : ""
              } ${sidebarPosition && sidebarPosition === "left"
                ? styles.spaceRight
                : styles.spaceLeft
              }`}
          >

          </div>
        </div>
      </div>
    </div>
  );
};

function listPosts(isMobile, data, offset) {  
  let maxEntriesInitial = NR_OF_ENTRIES_LOADED_INITIALLY;  
  const posts = [];  
  let count = 0;
  for (const [index, value] of data.entries()) {        
    count++;
    if (count > (maxEntriesInitial + offset)) {
      // do nothing
    } else {
      posts.push(singlePost( isMobile,index, value));
    }
  }
  return {posts,count};
}



function singlePost(isMobile, index, item) {  
  const linkToPost = process.env.PUBLIC_URL + "blog/blog-post/" + item.postId;
  const lang = getLanguageId(i18n);
  let title = item.titleEN;
  let previewText = item.previewTextEN;
  let date = item.date;

  if (lang === 'de') {
    title = item.titleDE;
    previewText = item.previewTextDE;
    let year = date.substring(0, 4);    
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    date = day + "." + month + "." + year;
  } 

  if (index % 2 === 1) {
    return <div key={index} className={`${styles.blogList}`}>
      {item.previewImage && !isMobile &&      
        <div className={styles.thumb}>
          <I18nLink path={linkToPost} content={
            <img
              src={item.previewImage}
              alt={title}
            />} />
        </div>}
      

      <div className={styles.content}>
        <h2 >
          <I18nLink path={linkToPost} content={
            title
          } />
        </h2>
        {/*<ul className={styles.blogMeta}>
          <li>{date}</li>
        </ul>*/}
        <p >
          {previewText}
        </p>

      </div>
    </div>
  }


  return <div key={index} className={styles.blogList}>
    <div className={styles.content}>
      <h2>
        <I18nLink path={linkToPost} content={
          title
        } />
      </h2>
      {/*<ul className={styles.blogMeta}>
        <li>{date}</li>
      </ul>*/}
      <p>
        {previewText}
      </p>
    </div>
    {item.previewImage && !isMobile && 
      <div className={styles.thumb}>
        <I18nLink path={linkToPost} content={
          <img
            src={item.previewImage}
            alt={title}
          />} />
      </div>
    }
  </div>
}

export default MarkdownBlogOverview;

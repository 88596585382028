import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import OnlineCourses from "./pages/OnlineCourses";
import OnlineKurse from "./pages/OnlineKurse";
import YouTube from "./pages/YouTube";
import LanguageRedirect from "./pages/LanguageRedirect";
import About from "./pages/About";
import Contact from "./pages/Contact";
import BlogPost from "./pages/BlogPost";
import NotFound from "./pages/NotFound";
import Impressum from "./pages/Impressum";
import CourseSupport from "./pages/CourseSupport";
import CourseHelpArticle from "./pages/CourseHelpArticle";
import DataPrivacy from "./pages/DataPrivacy";

function App() {

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/blog"}`}
            component={YouTube}
          />          
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={LanguageRedirect}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/impressum"}`}
            component={Impressum}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/dataprivacy"}`}
            component={DataPrivacy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/courses"}`}
            component={OnlineCourses}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/support"}`}
            component={CourseSupport}
          ></Route>
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:lang/coursearticle/:postId"}
            component={CourseHelpArticle}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/about"}`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/blog/blog-post/:postId"}`}
            component={BlogPost}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/:lang/contact"}`}
            component={Contact}
          />                   
          <Route
            exact
            path={process.env.PUBLIC_URL + "/not-found"}
            component={NotFound}
          />          
          <Route
            exact
            path={process.env.PUBLIC_URL + "/:lang/kurse"}
            component={OnlineKurse}
          />          


          {/* Legacy redirects */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/course-help"}`}>
            <Redirect to="/en/support" />
          </Route>          
          <Route
            exact
            path={process.env.PUBLIC_URL + "/kurshilfe"}
          >
            <Redirect to={`${process.env.PUBLIC_URL + "/de/support"}`} />
          </Route>          
          <Route
            exact
            path={process.env.PUBLIC_URL + "/kursartikel/intellij-beginner-de"}
          >
            <Redirect to={`${process.env.PUBLIC_URL + "/de/coursearticle/intellij-beginner-de"}`} />
          </Route>   
          <Route
            exact
            path={process.env.PUBLIC_URL + "/kursartikel/sts-import-de"}
          >
            <Redirect to={`${process.env.PUBLIC_URL + "/de/coursearticle/sts-import-de"}`} />
          </Route> 
          <Route
            exact
            path={process.env.PUBLIC_URL + "/kursartikel/project-import-de"}
          >
            <Redirect to={`${process.env.PUBLIC_URL + "/de/coursearticle/project-import-de"}`} />
          </Route>   
        

          {/* Language default redirects */}

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/de"}`}>
            <Redirect to={`${process.env.PUBLIC_URL + "/de/blog"}`} />
          </Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/en"}`}>
            <Redirect to={`${process.env.PUBLIC_URL + "/en/blog"}`} />
          </Route>
          <Route exact component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}


export default App;

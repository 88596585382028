import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import MarkDownView from "../containers/blog/markdown-blog-post";
import { useTranslation } from 'react-i18next';
import { getLanguageId } from '../components/translation'
import i18n from 'i18next';

const CourseHelpArticle = () => {

  const {t} = useTranslation("courses");
  const lang = getLanguageId(i18n);
  
  return (
    
    <Fragment>
      <MetaTags>
        <title>{t("helparticle")} </title>
        <meta
          name="description"
          content={t("helparticle")}
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title={t("article")}
                  subNav={ {href: "support" , text: t("support")}}
                  subSubNav={ {href: "courses" , text: t("onlinecourses")} }
         />
        {/* post details with sidebar */}
        <MarkDownView pathPrefix='/coursehelp' lang={lang}/>
      </LayoutOne>
    </Fragment>
  );
};

export default CourseHelpArticle;

import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import HelpFeatured from "../containers/help/help-featured";

const OnlineKurse = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Online Kurse</title>
        <meta
          name="description"
          content="Eine Übersicht meiner Online Kurse mit Hilfe und zusätzlichen Inhalten in deutscher Sprache"
        />
      </MetaTags>
      <LayoutOne lang="de">        
        <Breadcrumb title="Online Kurse" />
        <HelpFeatured lang="de" />
      </LayoutOne>
    </Fragment>
  );
};

export default OnlineKurse;

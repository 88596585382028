import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { IoLogoGithub, IoLogoYoutube } from "react-icons/io";
import { MdExpandLess } from "react-icons/md";
import styles from "./Footer.module.scss";
import { useTranslation } from 'react-i18next';
import I18nLink from '../../../components/translation'

const Footer = ({ footerBg }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const { t } = useTranslation("footer");

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <footer
      className={`footer-area ${footerBg === "white" ? styles.footer2 : ""}`}
    >
      <div
        className={`${styles.footerTop} ${footerBg === "white" ? "bg--cart-7" : "bg--cart-2"
          }`}
      >
        <div className="container">
          <div className="row">
            {/* Start Single Wedget */}
            <div className="col-12 col-sm-6 col-lg-3">
              <div className={styles.singleWidget}>
                <div className={styles.logo}>

                </div>
                <div className={styles.content}>
                  <p>
                    {t('text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.copyright}  ${footerBg === "white" ? "bg--cart-8" : "bg--cart-3"
          }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6">
              <div className={styles.copyrightInner}>
                <p>{t('copyright')}</p>
                <I18nLink ns="footer" id="impressum" />
                <br></br>
                <I18nLink ns="footer" id="dataprivacy" />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className={styles.ftSocialLink}>
                <ul className={styles.socialIcon}>
                  <li>
                    <a href='https://github.com/Christian-Oette'>
                      <IoLogoGithub />
                    </a>
                  </li>
                  <li>
                    <a href='https://www.youtube.com/channel/UCcXPq0rvN_0-elBiY23C7pA'>
                      <IoLogoYoutube />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <MdExpandLess />
      </button>
    </footer>
  );
};

Footer.propTypes = {
  footerBg: PropTypes.string
};

export default Footer;

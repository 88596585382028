import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Button from "../../components/UI/button";
import styles from "../../components/UI/button/Button.module.scss";

const I18nLink = ({path, ns, id, content, className}) => {    
    const namespace = ns || "common";
    const { t, i18n } = useTranslation(namespace); 
    const text = content || t(id);
    const lang = getLanguageId(i18n);
    const to = path || id;    

    const pathSuffix = to ? "/" + to : "";

    return <Link className={className} to={ process.env.PUBLIC_URL + "/" + lang + pathSuffix}>{text}</Link>
}

export const I18nButton = ({path, ns, id, url}) => {    
    const namespace = ns || "common";
    const { t, i18n } = useTranslation(namespace); 
    const lang = getLanguageId(i18n);
    const text = t(id);
    const to = path || id;    
    const pathSuffix = to ? "/" + to : "";
    if (url) {
        return <a className={`${styles.voopoBtn}`} href={url}>{text}</a>
    } else {
        const toUrl = process.env.PUBLIC_URL + "/" + lang + pathSuffix
        return <Button type="link" url={toUrl} text={text}/>
    }
}



export function getLanguageId(i18n) {
    let language = getNavigatorLanguage();
    if (i18n) {
        language = i18n.language || language;
    }    
    return isGerman(language) ? "de" : "en";
}

const getNavigatorLanguage = () => navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
const isGerman = (lang) => typeof lang === 'string' && lang.toLowerCase().includes("de");

export default I18nLink
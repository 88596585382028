import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import CourseHelpList from "../containers/course-help-list";
import courseInformation from '../assets/course/course-meta.json';
import { getLanguageId } from '../components/translation'
import { useTranslation } from 'react-i18next';

const CourseSupport = () => {

  const {i18n , t} = useTranslation("courses");
  const lang = getLanguageId(i18n);  
  const items = (lang === 'de') ? courseInformation.de : courseInformation.en;

  return (
    <Fragment>
      <MetaTags>
        <title>{t("support")}</title>
      </MetaTags>
      <LayoutOne>        
        <Breadcrumb title={t("support")} subNav={ {text: t("onlinecourses"), href:'courses'}} />
        <CourseHelpList articleUrlPrefix="/coursearticle" items={items}/>
      </LayoutOne>
    </Fragment>
  );
};

export default CourseSupport;

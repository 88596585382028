import React from "react";
import CourseSideBar from "../course-sidebar";
import styles from "../blog/BlogPostWithSidebar.module.scss";
import I18nLink from '../../components/translation';

const CourseHelpList = ({ items }) => {
  const { troubleshoot, additional } = items;
  const rows = [];

  for (const index in additional) {
    rows.push(createRow(index, additional[index]));
  }
  for (const index in troubleshoot) {
    rows.push(createRow(index + 99000, troubleshoot[index]));
  }

  return (
    <div className="voopo__blog__area pb--120">
      <div className="container">
        <div className="row">
          <div className={` col-12 col-lg-8 order-1 order-lg-2 `} >
            <div className={styles.blogListWrapper}>
              {rows}
            </div>
          </div>
          <div className={`col-12 col-lg-4 sm__mt--40 md__mt--40 order-2 order-lg-1 ${styles.spaceRight}`}>
            <CourseSideBar items={items} />
          </div>
        </div>
      </div>
    </div>
  );
};

function createRow( index, data) {
  return <div key={index} className={styles.blogList}>
    <div className={styles.content}>
      <h2>
        <I18nLink path={"coursearticle/"+data.helpId} content={data.title}></I18nLink>        
      </h2>
      <p>
        {data.previewText}
      </p>
    </div>
  </div>
}

export default CourseHelpList;

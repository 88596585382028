import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import MarkDownView from "../containers/blog/markdown-blog-post";
import blogMetaInformation from '../assets/blog/blog-meta.json';
import { getLanguageId } from '../components/translation'
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router";

const BlogPost = () => {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation(); 
  const lang = getLanguageId(i18n);  
  const { postId } = useParams();

  let post = {}  

  // eslint-disable-next-line no-unused-vars
  for (const [index, item] of blogMetaInformation.entries()) {
    if (postId === item.postId) {
      post = item;      
    }
  }

  let title = post.titleEN;  

  if (lang === "de") {
    title = post.titleDE;    
  }

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta
          name={t("blogpost")}
          content={t("blogpost")}
        />
        <meta property="og:url" content="https://christianoette.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t("blogpost")} />
        <meta property="og:image" content={"https://christianoette.com" + post.previewImage} />
        <meta property="og:description" content={title} />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title={t("blogpost")} subNav={{ href: "blog", text: "Blog & YouTube" }} />
        {/* post details with sidebar */}
        <MarkDownView pathPrefix='/blog/posts' lang='en' />
      </LayoutOne>
    </Fragment>
  );
};

export default BlogPost;
